// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

.MuiAppBar-root {
  background-color: #CC0033;
}

.MuiButton-textPrimary {
  color: #000000;
}

.MuiTypography-h6 {
  color: #000000;
}

.MuiContainer-root {
  padding-top: 16px;
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":["/* src/index.css */\nbody {\n  font-family: 'Roboto', sans-serif;\n  background-color: #f5f5f5;\n}\n\n.MuiAppBar-root {\n  background-color: #CC0033;\n}\n\n.MuiButton-textPrimary {\n  color: #000000;\n}\n\n.MuiTypography-h6 {\n  color: #000000;\n}\n\n.MuiContainer-root {\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
