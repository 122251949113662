// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc, doc, updateDoc, deleteDoc, query, where, getDoc, setDoc, limit, startAfter } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAyGUmZsvS1-bA_NTV5xV6IpVcizJr6nb0",
    authDomain: "ruclasses-684e8.firebaseapp.com",
    projectId: "ruclasses-684e8",
    storageBucket: "ruclasses-684e8.appspot.com",
    messagingSenderId: "612487768746",
    appId: "1:612487768746:web:0fd1670f29a67541313ed3",
    measurementId: "G-1NTE55BYN0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {
    db,
    auth,
    provider,
    collection,
    getDocs,
    addDoc,
    limit,
    startAfter,
    doc,
    updateDoc,
    deleteDoc,
    query,
    where,
    getDoc,
    setDoc
};
