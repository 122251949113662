// src/subjects.js
export const subjects = [
    { code: "010", name: "ACCOUNTING" },
    { code: "011", name: "ADMINISTRATIVE STUDIES" },
    { code: "016", name: "AFRICAN STUDIES" },
    { code: "013", name: "AFRICAN, MIDDLE EASTERN, AND SOUTH ASIAN LANGUAGES AND LITERATURES" },
    { code: "014", name: "AFRICANA STUDIES" },
    { code: "035", name: "AGRICULTURAL AND NATURAL RESOURCE MANAGEMENT" },
    { code: "020", name: "AGRICULTURE AND FOOD SYSTEMS" },
    { code: "047", name: "ALCOHOL STUDIES" },
    { code: "050", name: "AMERICAN STUDIES" },
    { code: "067", name: "ANIMAL SCIENCE" },
    { code: "070", name: "ANTHROPOLOGY" },
    { code: "074", name: "ARABIC LANGUAGES" },
    { code: "078", name: "ARMENIAN" },
    { code: "080", name: "ART" },
    { code: "081", name: "ART" },
    { code: "082", name: "ART HISTORY" },
    { code: "090", name: "ARTS AND SCIENCES" },
    { code: "098", name: "ASIAN STUDIES" },
    { code: "115", name: "BIOCHEMISTRY" },
    { code: "117", name: "BIOENVIRONMENTAL ENGINEERING" },
    { code: "119", name: "BIOLOGICAL SCIENCES" },
    { code: "122", name: "BIOMATHEMATICS" },
    { code: "125", name: "BIOMEDICAL ENGINEERING" },
    { code: "126", name: "BIOTECHNOLOGY" },
    { code: "136", name: "BUSINESS ANALYTICS AND INFORMATION TECHNOLOGY" },
    { code: "140", name: "BUSINESS LAW" },
    { code: "146", name: "CELL BIOLOGY AND NEUROSCIENCE" },
    { code: "155", name: "CHEMICAL AND BIOCHEMICAL ENGINEERING" },
    { code: "158", name: "CHEMICAL BIOLOGY" },
    { code: "160", name: "CHEMISTRY" },
    { code: "165", name: "CHINESE" },
    { code: "175", name: "CINEMA STUDIES" },
    { code: "180", name: "CIVIL AND ENVIRONMENTAL ENGINEERING" },
    { code: "190", name: "CLASSICS" },
    { code: "185", name: "COGNITIVE SCIENCE" },
    { code: "192", name: "COMMUNICATION" },
    { code: "189", name: "COMMUNICATION AND MEDIA STUDIES" },
    { code: "193", name: "COMMUNITY HEALTH OUTREACH" },
    { code: "195", name: "COMPARATIVE LITERATURE" },
    { code: "198", name: "COMPUTER SCIENCE" },
    { code: "202", name: "CRIMINAL JUSTICE" },
    { code: "203", name: "DANCE" },
    { code: "206", name: "DANCE" },
    { code: "207", name: "DANCE EDUCATION" },
    { code: "219", name: "DATA SCIENCE" },
    { code: "216", name: "ECOLOGY, EVOLUTION AND NATURAL RESOURCES" },
    { code: "220", name: "ECONOMICS" },
    { code: "300", name: "EDUCATION" },
    { code: "364", name: "EDUCATIONAL OPPORTUNITY FUND" },
    { code: "332", name: "ELECTRICAL AND COMPUTER ENGINEERING" },
    { code: "355", name: "ENGLISH - COMPOSITION AND WRITING" },
    { code: "351", name: "ENGLISH - CREATIVE WRITING" },
    { code: "354", name: "ENGLISH - FILM STUDIES" },
    { code: "353", name: "ENGLISH - LITERARY THEORY" },
    { code: "358", name: "ENGLISH - LITERATURE" },
    { code: "359", name: "ENGLISH - THEORIES AND METHODS" },
    { code: "356", name: "ENGLISH AS A SECOND LANGUAGE" },
    { code: "370", name: "ENTOMOLOGY" },
    { code: "382", name: "ENTREPRENEURSHIP" },
    { code: "015", name: "ENVIRONMENTAL AND BIOLOGICAL SCIENCES" },
    { code: "373", name: "ENVIRONMENTAL AND BUSINESS ECONOMICS" },
    { code: "573", name: "ENVIRONMENTAL PLANNING AND DESIGN" },
    { code: "374", name: "ENVIRONMENTAL POLICY, INSTITUTIONS AND BEHAVIOR" },
    { code: "375", name: "ENVIRONMENTAL SCIENCES" },
    { code: "381", name: "ENVIRONMENTAL STUDIES" },
    { code: "522", name: "ETHICS IN BUSINESS ENVIRONMENT" },
    { code: "360", name: "EUROPEAN STUDIES" },
    { code: "001", name: "EXCHANGE" },
    { code: "377", name: "EXERCISE SCIENCE" },
    { code: "387", name: "FILM STUDIES" },
    { code: "211", name: "FILMMAKING" },
    { code: "390", name: "FINANCE" },
    { code: "400", name: "FOOD SCIENCE" },
    { code: "420", name: "FRENCH" },
    { code: "440", name: "GENERAL ENGINEERING" },
    { code: "447", name: "GENETICS" },
    { code: "450", name: "GEOGRAPHY" },
    { code: "460", name: "GEOLOGICAL SCIENCES" },
    { code: "470", name: "GERMAN" },
    { code: "490", name: "GREEK" },
    { code: "489", name: "GREEK, MODERN" },
    { code: "501", name: "HEALTH ADMINISTRATION" },
    { code: "505", name: "HINDI" },
    { code: "510", name: "HISTORY" },
    { code: "508", name: "HISTORY - AFRICA, ASIA, LATIN AMERICA" },
    { code: "512", name: "HISTORY - AMERICAN" },
    { code: "506", name: "HISTORY - GENERAL/ COMPARATIVE" },
    { code: "533", name: "HUMAN RESOURCE MANAGEMENT" },
    { code: "535", name: "HUNGARIAN" },
    { code: "540", name: "INDUSTRIAL AND SYSTEMS ENGINEERING" },
    { code: "547", name: "INFORMATION TECHNOLOGY AND INFORMATICS" },
    { code: "557", name: "INTERDISCIPLINARY - MASON GROSS" },
    { code: "554", name: "INTERDISCIPLINARY STUDIES" },
    { code: "555", name: "INTERDISCIPLINARY STUDIES" },
    { code: "556", name: "INTERDISCIPLINARY STUDIES - ARTS AND SCIENCES" },
    { code: "558", name: "INTERNATIONAL STUDIES" },
    { code: "560", name: "ITALIAN" },
    { code: "565", name: "JAPANESE" },
    { code: "563", name: "JEWISH STUDIES" },
    { code: "567", name: "JOURNALISM AND MEDIA STUDIES" },
    { code: "574", name: "KOREAN" },
    { code: "575", name: "LABOR STUDIES" },
    { code: "550", name: "LANDSCAPE ARCHITECTURE" },
    { code: "617", name: "LANGUAGES AND CULTURES" },
    { code: "580", name: "LATIN" },
    { code: "590", name: "LATIN AMERICAN STUDIES" },
    { code: "595", name: "LATINO AND HISPANIC CARIBBEAN STUDIES" },
    { code: "607", name: "LEADERSHIP SKILLS" },
    { code: "615", name: "LINGUISTICS" },
    { code: "620", name: "MANAGEMENT" },
    { code: "624", name: "MANAGEMENT AND WORK" },
    { code: "628", name: "MARINE SCIENCES" },
    { code: "630", name: "MARKETING" },
    { code: "632", name: "MASON GROSS - DIGITAL FILMMAKING" },
    { code: "635", name: "MATERIALS SCIENCE AND ENGINEERING" },
    { code: "640", name: "MATHEMATICS" },
    { code: "650", name: "MECHANICAL AND AEROSPACE ENGINEERING" },
    { code: "652", name: "MEDICAL ETHICS AND POLICY" },
    { code: "660", name: "MEDICAL TECHNOLOGY" },
    { code: "667", name: "MEDIEVAL STUDIES" },
    { code: "670", name: "METEOROLOGY" },
    { code: "680", name: "MICROBIOLOGY" },
    { code: "685", name: "MIDDLE EASTERN AND ISLAMIC STUDIES" },
    { code: "690", name: "MILITARY EDUCATION, AIR FORCE" },
    { code: "691", name: "MILITARY EDUCATION, ARMY" },
    { code: "692", name: "MILITARY EDUCATION, NAVY" },
    { code: "694", name: "MOLECULAR BIOLOGY AND BIOCHEMISTRY" },
    { code: "700", name: "MUSIC" },
    { code: "701", name: "MUSIC, APPLIED" },
    { code: "705", name: "NURSING" },
    { code: "709", name: "NUTRITIONAL SCIENCES" },
    { code: "711", name: "OPERATIONS RESEARCH" },
    { code: "713", name: "ORGANIZATIONAL LEADERSHIP" },
    { code: "723", name: "PERSIAN" },
    { code: "715", name: "PHARMACEUTICAL CHEMISTRY" },
    { code: "721", name: "PHARMACEUTICS" },
    { code: "718", name: "PHARMACOLOGY, CELLULAR AND MOLECULAR" },
    { code: "720", name: "PHARMACY" },
    { code: "725", name: "PHARMACY PRACTICE AND ADMINISTRATION" },
    { code: "730", name: "PHILOSOPHY" },
    { code: "745", name: "PHYSICIAN ASSISTANT" },
    { code: "750", name: "PHYSICS" },
    { code: "762", name: "PLANNING AND PUBLIC POLICY" },
    { code: "776", name: "PLANT SCIENCE" },
    { code: "775", name: "POLICY, HEALTH, AND ADMINISTRATION" },
    { code: "787", name: "POLISH" },
    { code: "790", name: "POLITICAL SCIENCE" },
    { code: "810", name: "PORTUGUESE" },
    { code: "830", name: "PSYCHOLOGY" },
    { code: "843", name: "PUBLIC ADMINISTRATION AND MANAGEMENT" },
    { code: "832", name: "PUBLIC HEALTH" },
    { code: "833", name: "PUBLIC POLICY" },
    { code: "851", name: "REAL ESTATE" },
    { code: "840", name: "RELIGION" },
    { code: "860", name: "RUSSIAN" },
    { code: "902", name: "SEBS INTERNSHIP" },
    { code: "888", name: "SEXUALITIES STUDIES" },
    { code: "861", name: "SLAVIC AND EAST EUROPEAN STUDIES" },
    { code: "904", name: "SOCIAL JUSTICE" },
    { code: "910", name: "SOCIAL WORK" },
    { code: "920", name: "SOCIOLOGY" },
    { code: "940", name: "SPANISH" },
    { code: "955", name: "SPORT MANAGEMENT" },
    { code: "960", name: "STATISTICS" },
    { code: "959", name: "STUDY ABROAD" },
    { code: "799", name: "SUPPLY CHAIN AND MARKETING SCIENCE" },
    { code: "956", name: "SWAHILI" },
    { code: "965", name: "THEATER" },
    { code: "966", name: "THEATER ARTS" },
    { code: "973", name: "TURKISH" },
    { code: "974", name: "TWI" },
    { code: "973", name: "UKRANIAN" },
    { code: "971", name: "URBAN PLANNING AND DESIGN" },
    { code: "975", name: "URBAN STUDIES AND COMMUNITY DEVELOPMENT" },
    { code: "988", name: "WOMEN'S, GENDER, AND SEXUALITY STUDIES" },
    { code: "991", name: "WORLD LANGUAGES" }
];
